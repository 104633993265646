<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>Acuse de recibo de boleta de calificaciones</h3>
            <div class="text-muted text-body-1">
              Generación de acuse de recibo de boleta de calificaciones
            </div>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->
      <div class="card-body px-1 px-sm-5 pt-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-0 rounded-lg mb-5" outlined>
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row class="d-none">
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <!-- begin:year combo -->
                      <v-select
                        label="¿Qué año desea consultar?"
                        filled
                        rounded
                        :loading="areYearsLoading"
                        :disabled="areYearsLoading"
                        :items="availableYears"
                        item-text="year"
                        item-value="year"
                        v-model="selectedYear"
                        @click="resetReportParams()"
                        @change="
                          loadGrades();
                          getStudents();
                        "
                      >
                        <!-- begin::selected grade item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.year }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected grade item -->
                      </v-select>
                      <!-- end:grade combo -->
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5"
                    ><v-col>
                      <p class="ma-0 pa-0 text-body-1">
                        <strong>
                          Debe seleccionar un grado, luego dependiendo del grado
                          seleccionado se debe seleccionar el grupo académico o
                          un grupo específico de una especialidad y por último,
                          una etapa.
                        </strong>
                      </p>
                    </v-col></v-row
                  >
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <!-- begin:grade combo -->
                        <v-select
                          label="Grado"
                          filled
                          rounded
                          hide-details
                          :items="grades"
                          item-text="name"
                          item-value="id"
                          v-model="report.params.grade_id"
                          @click="resetReportParams()"
                          @change="
                            loadAcademicGroups(),
                              loadTechnicalGroups(),
                              loadStagesByGrade()
                          "
                        >
                          <!-- begin::selected grade item -->
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                          </template>
                          <!-- end::selected grade item -->
                        </v-select>
                        <!-- end:grade combo -->
                      </div>
                    </v-col>
                    <!-- end::select academic level filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <!-- begin:: academicGroup combo -->
                      <v-select
                        v-if="academicLevelId != BACHILLERATO_ID"
                        :label="
                          areAcademicGroupsLoading
                            ? 'Cargando grupos académicos..'
                            : 'Grupo académico'
                        "
                        :loading="areAcademicGroupsLoading"
                        :disabled="
                          areAcademicGroupsLoading ||
                          !academicGroups.length ||
                          !report.params.grade_id
                        "
                        filled
                        rounded
                        hide-details
                        clearable
                        :items="academicGroups"
                        item-text="grade_section_group_formatted"
                        item-value="id"
                        v-model="report.params.grade_section_group_id"
                      >
                        <!-- begin::selected academicGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              item.grade_section_group_formatted
                            }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected academicGroup item -->
                      </v-select>
                      <!-- end:: academicGroup combo -->

                      <!-- begin:: technicalGroups combo -->
                      <v-select
                        v-else
                        :label="
                          areTechnicalGroupsLoading
                            ? 'Cargando grupos técnicos..'
                            : 'Grupo técnico'
                        "
                        :loading="areTechnicalGroupsLoading"
                        :disabled="
                          areTechnicalGroupsLoading ||
                          !technicalGroups.length ||
                          !report.params.grade_id
                        "
                        filled
                        rounded
                        hide-details
                        clearable
                        :items="technicalGroups"
                        item-text="grade_speciality_group_formatted"
                        item-value="id"
                        v-model="report.params.grade_speciality_group_id"
                      >
                        <!-- begin::selected technicalGroup item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{
                              item.grade_speciality_group_formatted
                            }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected technicalGroup item -->
                      </v-select>
                      <!-- end:: technicalGroups combo -->
                    </v-col>
                    <!-- end::select section/group filter-->

                    <!-- begin::select stage filter -->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <v-select
                        :label="
                          areStagesloading ? 'Cargando etapas..' : 'Etapa'
                        "
                        :loading="areStagesloading"
                        :disabled="
                          areStagesloading ||
                          !stages.length ||
                          !report.params.grade_id
                        "
                        filled
                        rounded
                        hide-details
                        clearable
                        :items="stages"
                        item-text="stage.name"
                        item-value="stage_id"
                        v-model="report.params.stage_id"
                      >
                        <!-- begin::selected stage item -->
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" small v-if="index === 0">
                            <span>{{ item.stage.name }}</span>
                          </v-chip>
                        </template>
                        <!-- end::selected stage item -->
                      </v-select>
                    </v-col>
                    <!-- end::select stage filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->

              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with students scores col-container-->
                  <v-col cols="12" sm="8">
                    <!-- begin::excel with students scores-->
                    <v-card
                      outlined
                      :disabled="!canGenerateReportCard"
                      class="rounded-lg"
                    >
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">estudiantes</div>
                          <v-list-item-title class="text-h5 mb-1">
                            Acuse de recibo de boleta de calificaciones
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            Acuse de recibo correspondiente al grupo académico o
                            grupo de la especialidad seleccionada.
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded red-pdf"
                          tile
                          icon
                          size="80"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-file-pdf-box</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          outlined
                          rounded
                          text
                          @click="getReportCardUrl()"
                        >
                          Generar acuses
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                    <!-- end::excel with students scores-->

                    <!-- begin::fallback card if not all necesary parameters have been selected -->

                    <!-- end::fallback card if not all necesary parameters have been selected -->
                  </v-col>
                  <!-- end::excel with students scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import reportRepository from "@/repositories/reportRepository";
import gradeRepository from "@/repositories/gradeRepository";
import stageRepository from "@/repositories/stageRepository";
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import studentRepository from "@/repositories/studentRepository";
import ApiService from "@/core/services/api.service";
import settingsRepository from "@/repositories/settingsRepository";

export default {
  name: "ReportCard",
  title: "Boleta de calificaciones | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes", route: "reports" },
      {
        title: "Acuse de recibo de boleta de calificaciones",
        route: "report_card",
      },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Boleta de calificaciones");
    //this.loadYears();
    this.loadGrades();
    // this.getStudents();
  },
  data() {
    return {
      availableYears: [],
      stages: [],
      grades: [],
      academicGroups: [],
      technicalGroups: [],
      academicScoresSheet: [],
      areAcademicGroupsLoading: false,
      areTechnicalGroupsLoading: false,
      areStagesloading: false,
      onlyOneStudent: false,
      TERCER_CICLO_ID: 1,
      BACHILLERATO_ID: 2,
      selectedYear: null,
      areYearsLoading: false,
      report: {
        params: {},
      },
      students: [],
      areStudentsLoading: false,
    };
  },
  methods: {
    loadYears() {
      this.areYearsLoading = true;
      settingsRepository
        .getAllYears()
        .then(({ data }) => {
          this.availableYears = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areYearsLoading = false;
        });
    },
    //Fetching all yearly stages
    loadStagesByGrade() {
      this.areStagesloading = true;
      stageRepository
        .stageByAcademicLevel(this.report.params.grade_id, 8, {
          headers: {
            year: `2024`,
          },
        })
        .then(({ data }) => {
          this.stages = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener las etapas",
          });
        })
        .finally(() => {
          this.areStagesloading = false;
        });
    },

    loadTechnicalGroups() {
      this.areTechnicalGroupsLoading = true;
      technicalGroupRepository
        .getTechnicalGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.technicalGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areTechnicalGroupsLoading = false;
        });
    },

    //Fetching all grade academic levels
    loadGrades() {
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.grades = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },

    //Fetching academic groups depending on the id of the grade that's been selected
    loadAcademicGroups() {
      this.areAcademicGroupsLoading = true;
      academicGroupRepository
        .getAcademicGroupsByGrade(this.report.params.grade_id)
        .then(({ data }) => {
          this.academicGroups = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.areAcademicGroupsLoading = false;
        });
    },

    getStudents() {
      this.areStudentsLoading = true;
      studentRepository
        .getAllWithAcademicInformationIncludingDisabled({
          headers: {
            year: `${this.selectedYear}`,
          },
        })
        .then(({ data }) => {
          this.students = data;
        })
        .catch((err) => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.areStudentsLoading = false;
        });
    },

    resetReportParams() {
      this.report.params = {};
    },

    goBack() {
      this.$router.go(-1);
    },

    async encryptCode(code) {
      try {
        // Se hace la petición para encriptar el id del grupo académico
        const { data: codeEncrypted } = await ApiService.get(
          `${this.reportsApiUrl}/version/u/${code}`
        );
        return codeEncrypted;
      } catch (error) {
        // Se settea la variable true para ocultar el botón
        this.fireToast({
          icon: "error",
          title: "No ha sido posible cargar los datos desde el servidor",
        });
        return;
      }
    },

    async getReportCardUrl() {
      if (!this.canGenerateReportCard) {
        this.fireToast({
          icon: "error",
          title:
            "No es posible generar la boleta asegurate de haber especificado los parámetros necesarios",
        });
        return;
      }

      let url;
      // const year = await this.encryptCode(2024);

      if (this.academicLevelId === this.TERCER_CICLO_ID) {
        url = `${this.reportsApiUrl}/students/acknowledgment/grade-section-group/${this.report.params.grade_section_group_id}/stage/${this.report.params.stage_id}`;
        window.open(url, "_blank");
        return;
      } else {
        url = `${this.reportsApiUrl}/students/acknowledgment/grade-speciality-group/${this.report.params.grade_speciality_group_id}/stage/${this.report.params.stage_id}`;
        window.open(url, "_blank");
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),

    reportsApiUrl() {
      let baseURL;
      if (process.env.REPORTS_APP_ENV === "production") {
        baseURL = process.env.REPORTS_API_URL;
      } else if (process.env.REPORTS_APP_ENV === "development") {
        baseURL = process.env.REPORTS_DEV_API_URL;
      } else {
        baseURL = process.env.REPORTS_LOCAL_API_URL;
      }
      return baseURL;
    },

    yearHasBeenSelected() {
      return !!this.selectedYear;
    },

    canGenerateReportCard() {
      return (
        this.academicGroupOrTechnicalGroupBeenSelected &&
        this.stageHasBeenSelected
      );
    },

    academicGroupOrTechnicalGroupBeenSelected() {
      return (
        !!this.report.params.grade_section_group_id ||
        !!this.report.params.grade_speciality_group_id
      );
    },

    stageHasBeenSelected() {
      return !!this.report.params.stage_id;
    },

    academicLevelId() {
      if (!("grade_id" in this.report.params)) {
        return;
      }

      const academicLevel = this.grades.find(
        (item) => item.id == this.report.params.grade_id
      );
      return academicLevel.academic_level.id;
    },

    filteredStudents() {
      return this.filterData(this.report.params, this.students);
    },
  },
  watch: {
    onlyOneStudent(newValue, oldValue) {
      if (!newValue) {
        this.report.params.code = null;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style>
.red-pdf {
  background-color: #d93025 !important;
}
.student-divider {
  border-bottom: 1px solid rgb(202, 202, 202);
}
</style>
